/* eslint-disable prettier/prettier */
// env object
const envObject: { [k: string]: string } = {
  FirebaseApiKey: "AIzaSyAXIZ-Ca8PVtJjvhRyIdG8xKVwGLEMqo24" || '',
  FirebaseAuthDomain: 'ziptrip-app.firebaseapp.com' || '',
  FirebaseProjectId: 'ziptrip-app' || '',
  FirebaseStorageBucket: 'stage-ziptrip-app' || '',
  FirebaseMessagingSenderId: "747443009482" || '',
  FirebaseAppId: "1:747443009482:web:49412c6f174f5e0fa70133" || '',
  FirebaseMeasurementId: "G-X71ECWCF52" || '',
  GoogleMapApiKey: "AIzaSyBTYJ2lq7x-Eiw0h2ilhBcrWQpgeFu1SBI" || '',
  BaseUrl: 'https://ziptrip-app.el.r.appspot.com/api' || '',
  StripePK: "pk_test_51Mi0G1Eo9bhYbAJFgkTAZMTY1Jy4iyHPwVNoJy2juigNBh7aTEmKHGLYaFMUGWPgRHXNVonk3rAhwh4daanEIE6800hxMv2bzC" || '',
  StripeOAuthId: "ca_NSwF81gYJyCFIsgdR63MHEXZdhUs6RRu"
 || '',
  CfToken: process.env.REACT_APP_CloudFlareToken || '',
};

export const getEnv = (key: string) => {
  return envObject[key];
};
